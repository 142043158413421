import React, {  } from "react";
import { graphql } from 'gatsby';
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { Event } from "../components/Event";
import { Post } from "../components/Post";
import { LogOpen } from "../components/LogOpen";

import "./styles.scss"

export default function ArticlePage({ data }) {

  const {
    body,
    slug,
    frontmatter: { title, date, full }
  } = data.mdx;
  const posts = data.allMdx.nodes.filter((n) => n.slug.startsWith('posts')).sort((a,b) => new Date(a.frontmatter.date) - new Date(b.frontmatter.date));
  const index = posts.findIndex(post => post.slug === slug)
  const nextIndex = index + 1;
  const prevIndex = index - 1;
  const hasNext = nextIndex < posts.length;
  const hasPrevious = prevIndex >= 0;

  return (
    <>
    <LogOpen page={slug} />
      {slug.startsWith('posts') && <>
        <RainbowMenu active="Galerie" relativePath="../../"></RainbowMenu>
      </>}
      <div className="columns">
        <div className="column is-3">
          <Sidebar></Sidebar>
        </div>
        <div className="column is-9">
          {slug.startsWith('posts') && <>
            <Post body={body} slug={slug} title={title} date={date} image={full} nextSlug={hasNext ? posts[nextIndex].slug : null} prevSlug={hasPrevious ? posts[prevIndex].slug : null} />
          </>}
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ARTICLE_BY_SLUG($slug: String) {
    mdx(slug: { eq: $slug }) {
      id
      slug
      body
      frontmatter {
        date
        title
        full: image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  allMdx {
    nodes {
      id
      fileAbsolutePath
      slug
      frontmatter {
        date
      }
    }
  }
  }
`;