import React, { useEffect, useState } from "react";
import { MDXRenderer } from "gatsby-plugin-mdx"
import { RainbowMenu } from "../components/RainbowMenu";
import { Sidebar } from "../components/Sidebar";
import { EventSubscription } from "../components/EventSubscription";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { faForward, faBackward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

export const Post = ({ body, slug, title, date, image, nextSlug, prevSlug }) => {

  date = new Date(date);
  const dateAsStr = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="columns is-vcentered mb-0">
        <div className="column is-1">
          {prevSlug &&
            <Link to={'../../' + prevSlug} className="button">
              <FontAwesomeIcon icon={faBackward} />
            </Link>
          }
          {!prevSlug &&
            <button disabled={true} className="button">
              <FontAwesomeIcon icon={faBackward} />
            </button>
          }
        </div>
        <div className="column is-10">
          <h2 className="title is-4">{title} [{dateAsStr}]</h2>
        </div>
        <div className="column is-1">
          {nextSlug &&
            <Link to={'../../' + nextSlug} className="button is-pulled-right">
              <FontAwesomeIcon icon={faForward} />
            </Link>
          }
          {!nextSlug &&
            <button disabled={true} className="button is-pulled-right">
              <FontAwesomeIcon icon={faForward} />
            </button>
          }
        </div>
      </div>
      <div className="mb-2">
        <GatsbyImage image={getImage(image)} alt={title} />
      </div>
      <MDXRenderer>{body}</MDXRenderer>
    </>
  );
}
